import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"480"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._v(_vm._s(_vm.description))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.confirmAction}},[_vm._v(" "+_vm._s(_vm.okayLabel)+" ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.cancelAction}},[_vm._v(" "+_vm._s(_vm.cancelLabel)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }