<template>
  <v-container>
    <v-toolbar flat color="toolbar-action transparent">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
        z-index="20"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="search.keyword"
            label=""
            placeholder="Search Groups"
            prepend-inner-icon="mdi-magnify"
            filled
            rounded
            dense
            clearable
            v-bind="attrs"
            v-on="on"
            class="v-input--search"
            @click:clear="clearSearchAction"
            @keydown.enter="searchAction()"
          ></v-text-field>
        </template>
        <GroupSearchForm @clicked-search-button="clickedSearchButtonInModal" :meta="meta" />
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn color="primary v-btn--new" dark class="ma-2" to="/groups/new">New</v-btn>
    </v-toolbar>
    <Paginate :meta="meta" v-on:paginate="paginateAction" />
    <v-data-table
      :headers="headers"
      :items="groups"
      :options.sync="options"
      @click:row="editItem"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:item.subgroups="{ item }">
        <span v-for="(subgroup, index) in item.subgroups" :key="subgroup.id">
          <router-link :to="`/subgroups/${subgroup.id}`" @click.stop class="base-link">{{
            subgroup.title
          }}</router-link>
          <span v-if="index != item.subgroups.length - 1">, </span>
        </span>
      </template>

      <template v-slot:item.start_date="{ item }">
        {{ formatDate(item.start_date) }}
      </template>

      <template v-slot:item.end_date="{ item }">
        {{ formatDate(item.end_date) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-spacer></v-spacer>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <ConfirmDialog
      title="Confirm delete"
      description="Are you sure you want to delete this item"
      okayLabel="Yes"
      cancelLabel="No"
      :show="showDeleteConfirmDialog"
      @confirm-dialog-clicked="confirmDelete"
    />
  </v-container>
</template>
<style scoped>
.v-input--search {
  max-width: 80%;
}
.v-btn--new {
  position: absolute;
  top: -12px;
  right: 0;
}
.v-menu__content {
  max-width: 640px;
  padding: 20px 20px 0 20px;
  background: white;
}
</style>
<script>
import { dateMixin } from '@/mixins/dateMixin';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import GroupSearchForm from '@/views/GroupSearchForm';
import Paginate from '@/views/Paginate';

export default {
  name: 'GroupsView',
  components: {
    ConfirmDialog,
    GroupSearchForm,
    Paginate
  },
  mixins: [dateMixin],
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1;
    store
      .dispatch('group/list', {
        page: currentPage,
        sort_by: 'id',
        sort_desc: true
      })
      .then(() => {
        to.params.page = currentPage;
        next();
      });
  },
  methods: {
    load: function () {
      store.dispatch('group/list', {
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'id',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    editItem(item) {
      this.$router.push({ path: `/groups/${item.id}` });
    },
    deleteItem(item) {
      this.group_id = item.id;
      this.showDeleteConfirmDialog = true;
    },
    confirmDelete: function (deleteItem) {
      this.showDeleteConfirmDialog = false;
      if (deleteItem) {
        this.processDelete();
      }
    },
    async processDelete() {
      await store.dispatch('group/delete', this.group_id);
      this.load();
    },
    paginateAction(item) {
      if (item == 'next') {
        store.dispatch('group/paginate', this.links.next);
      } else if (item == 'previous') {
        store.dispatch('group/paginate', this.links.prev);
      }
    },
    clickedSearchButtonInModal(options) {
      this.menu = false;
      const defaultOptions = {
        active: true,
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'id',
        sort_desc: this.options.sortDesc[0] ?? false
      };
      this.searchQuery({
        ...defaultOptions,
        ...options
      });
    },
    searchAction() {
      this.searchQuery({
        keyword: this.search.keyword,
        active: true,
        page: this.options.page,
        sort_by: this.options.sortBy[0] ?? 'id',
        sort_desc: this.options.sortDesc[0] ?? false
      });
    },
    searchQuery(options) {
      Object.keys(options).forEach(key => {
        if (options[key] === null || options[key] === undefined) {
          delete options[key];
        }
      });
      store.dispatch('group/search', options);
      this.search = options;
      this.menu = false;
    },
    clearSearchAction() {
      this.search = {
        keyword: '',
        active: false
      };
      this.load();
    }
  },
  computed: {
    ...mapGetters('group', ['loading', 'error', 'groups', 'meta', 'links'])
  },
  watch: {
    options: {
      handler() {
        if (this.options.init) {
          if (this.search.active) {
            this.search.page = this.options.page;
            this.search.sort_by = this.options.sortBy[0] ?? 'id';
            this.search.sort_desc = this.options.sortDesc[0] ?? false;
            this.searchQuery(this.search);
          } else {
            this.load();
          }
        } else {
          this.options.init = true;
        }
      },
      deep: true
    }
  },
  data: () => ({
    menu: false,
    search: {
      keyword: '',
      active: false
    },
    showDeleteConfirmDialog: false,
    options: {},
    headers: [
      { text: 'ID#', value: 'id' },
      { text: 'Group Name', value: 'title' },
      { text: 'Status', value: 'status' },
      { text: 'Subgroup', value: 'subgroups', sortable: false },
      { text: 'Client Name', value: 'client_name' },
      { text: 'Client #', value: 'client_reference' },
      { text: 'Start Date', value: 'start_date' },
      { text: 'End Date', value: 'end_date' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
    ]
  })
};
</script>
