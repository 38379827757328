<template>
  <v-container>
    <div class="pagination">
      <div class="pagination-summary" v-if="total > 0">{{ from }} - {{ to }} of {{ total }}</div>
      <v-btn @click="previousItem" :disabled="current_page == 1 || current_page == 0" icon
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-btn @click="nextItem" :disabled="current_page == last_page || current_page == 0" icon
        ><v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </div>
  </v-container>
</template>
<style scoped>
.pagination {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.pagination-summary {
  color: #757575;
  margin-right: 20px;
}
</style>
<script>
export default {
  name: 'Paginate',
  props: ['meta'],
  computed: {
    from: {
      get() {
        if (this.meta) {
          return this.meta.from;
        }
        return 0;
      }
    },
    to: {
      get() {
        if (this.meta) {
          return this.meta.to;
        }
        return 0;
      }
    },
    total: {
      get() {
        if (this.meta) {
          return this.meta.total;
        }
        return 0;
      }
    },
    current_page: {
      get() {
        if (this.meta) {
          return this.meta.current_page;
        }
        return 0;
      }
    },
    last_page: {
      get() {
        if (this.meta) {
          return this.meta.last_page;
        }
        return 0;
      }
    }
  },
  methods: {
    nextItem() {
      this.$emit('paginate', 'next');
    },
    previousItem() {
      this.$emit('paginate', 'previous');
    }
  },
  data: () => ({
    loading: true
  })
};
</script>
