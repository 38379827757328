<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="480">
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmAction"> {{ okayLabel }} </v-btn>
          <v-btn color="primary" text @click="cancelAction"> {{ cancelLabel }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: 'Confirm navigation'
    },
    description: {
      type: String,
      default: 'You have made changes to this page, are you sure you want to navigate away?'
    },
    okayLabel: {
      type: String,
      default: 'Continue'
    },
    cancelLabel: {
      type: String,
      default: 'Stay'
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancelAction() {
      this.$emit('confirm-dialog-clicked', false);
    },
    confirmAction() {
      this.$emit('confirm-dialog-clicked', true);
    }
  }
};
</script>
